<template>
  <!-- Jumbotron -->
  <section
    class="container-xxl position-relative jumbotron bg-shape my-auto px-4 px-5 px-md-6 px-xl-7 light-blue"
    style=""
  >
    <div class="col-lg-9 position-relative">
      <h1 class="display-4 mb-6 position-relative">
        Atomic X is revolutionizing the industry by
        <span class="d-block d-md-inline primary-color">
          re-thinking the process of engagement</span
        >
      </h1>
      <p class="h5 fw-normal position-relative">
        Based in Toronto, with a highly skilled team of AI Strategists, Computer
        Engineers, Data Scientists and UX Specialists, Atomic X offers AI
        consulting and custom development. We are uniquely positioned to help
        your organization gain an executive level understanding of how and when
        AI will impact your industry.
      </p>
    </div>
  </section>
  <!-- Jumbotron -->
</template>

<script>
export default {
  name: "Placeholder",
};
</script>

<style></style>
